/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
.desk {
  max-width: 88%;
}
.footcontent {
  font-size: 13px;
}
#expo div.link tr.cb-index-all {
  width: 88%;
  left: 6%;
}
div.base div.unit.fold div.ctrl {
  left: 6%;
  width: 88%;
}
.im-mood__claim {
  right: 24px;
  margin-right: 0;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 2%;
  margin-left: 2%;
}
.area .part,
.area > .grid table {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.area .tiny {
  width: 46%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 4%;
  margin-left: 4%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 92%;
}
.area > .slim .tiny {
  width: 92%;
}
.base {
  width: 100%;
}
.base > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.base h2,
.base .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.base .part,
.base > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.base > .slim .part,
.base > .slim.grid table {
  width: 100%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
@media only screen and (max-width: 849px) {
  .main {
    width: 104%;
    margin-left: -2%;
  }
  .farwest {
    width: 104%;
    margin-left: -2%;
  }
  .south {
    width: 104%;
    margin-left: -2%;
  }
}
.cb-layout1 .main .seam,
.cb-layout1 .south .seam,
.cb-layout3 .main .seam,
.cb-layout3 .main .seam,
.areaTen .seam {
  margin-left: 2%;
  margin-right: 2%;
  width: 46%;
}
div.base div.unit div.head,
div.base div.unit div.body,
div.base div.unit div.foot {
  max-width: 88%;
}
div.base div.unit.fold div.body {
  max-width: 100%;
}
div.base div.unit.fold div.body div.more {
  max-width: 88%;
}
div.areaEleven .seam .part.tall {
  float: right;
  margin-left: 20px !important;
  width: 66% !important;
}
div.areaEleven .seam .part.tiny {
  width: calc(34% - 20px) !important;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-medium.css.map */